import 'rc-collapse/assets/index.css'
import CheckboxFilter from './shared/CheckboxFilter'
import { CommonFilterProps } from './shared/audience-filters-types'

type MemberTypeFilterFilterProps = CommonFilterProps & {}

function MemberTypeFilter(props: MemberTypeFilterFilterProps) {
    return (
        <>
            <CheckboxFilter {...props} id="memberType.fullyRegistered" />
            <CheckboxFilter {...props} id="memberType.notFullyRegistered" />
            <CheckboxFilter {...props} id="memberType.unlockedOffers" />
        </>
    )
}

export default MemberTypeFilter
