import Collapse, { Panel } from 'rc-collapse'
import { useAudienceFilters } from './hook/useAudienceFilters'
import RetrievalMethodFilter from './FilterPanel/RetrievalMethodFilter'
import ShopperStatusFilter from './FilterPanel/ShopperStatusFilter'
import RegistrationDateFilter from './FilterPanel/RegistrationDateFilter'
import { XIcon } from '@heroicons/react/solid'
import './Filters.css'
import 'rc-collapse/assets/index.css'
import ChevronDown from '../../../../components/Icons/ChevronDown'
import { useTranslation } from 'react-i18next'
import { CommonFilterProps } from './FilterPanel/shared/audience-filters-types'
import moment from 'moment-timezone'
import MemberTypeFilter from './FilterPanel/MemberTypeFilter'
import Button, { ButtonAppearance } from '../../../../components/Button'

type FiltersProps = {
    onChange: (queryParams: string) => void
}

function Filters({ onChange }: FiltersProps) {
    const { getFilterValue, setFilterValue, removeFilterValue, isEnabled, values } = useAudienceFilters()
    const { t } = useTranslation('common')

    const endLineArrow = <ChevronDown className="w-6" />
    const props: CommonFilterProps = { getFilterValue, setFilterValue, removeFilterValue, isEnabled }

    const formatFilterValue = (v: boolean | Date) => (v instanceof Date ? moment(v).format('MM/DD/YYYY') : v)
    const activeFilters = values.map((v) => (
        <div key={v.id} className="mb-2 mr-2 p-2 bg-primary inline-block text-white">
            {t('audience.filters.' + v.id, { value: formatFilterValue(v.value) })}
            <XIcon className="w-8 inline-block px-2 cursor-pointer" onClick={() => removeFilterValue(v.id)} />
        </div>
    ))

    const submit = async () => {
        let query = ''
        values
            .filter((v) => ![undefined, null].includes(v.value))
            .forEach((v) => {
                if (v.id.startsWith('registrationDate')) {
                    query += '&' + v.id + '=' + moment(v.value).format('YYYY-MM-DD')
                } else if (v.id.startsWith('dataRetrievalMethod')) {
                    const splitIdValue = v.id.split('.')
                    query += '&' + splitIdValue[0] + '=' + splitIdValue[1].toUpperCase()
                } else if (v.id.startsWith('memberType')) {
                    if (v.id === 'memberType.unlockedOffers') {
                        query += '&memberWithUnlockedOffers=true'
                    } else {
                        query += '&memberFullyRegistered=' + (v.id === 'memberType.fullyRegistered')
                    }
                } else {
                    const splitIdValue = v.id.split('.')
                    query += '&' + splitIdValue[0] + '[]=' + splitIdValue[1]
                }
            })

        onChange(query.substring(1))
    }

    return (
        <div className="bg-white p-4 w-full" id="audience-filters">
            <div className="flex justify-between gap-2">
                <h3 className="text-2xl 2xl:w-1/3 lg:w-3/4 w-full flex gap-2">{t('menu.user.configuration')}</h3>
                <Button
                    disabled={!values.length}
                    appearance={ButtonAppearance.BUTTON_APPEARANCE_OUTLINE}
                    onClick={submit}
                    data-cy="search_button"
                >
                    {t('menu.user.apply')}
                </Button>
            </div>

            <div className="activeFilters">{activeFilters}</div>

            <Collapse>
                <Panel
                    showArrow={false}
                    extra={endLineArrow}
                    header={<span data-cy="retrieval_method_filter">{t('audience.filters.title')}</span>}
                >
                    <RetrievalMethodFilter {...props} />
                </Panel>

                <Panel
                    showArrow={false}
                    extra={endLineArrow}
                    header={<span data-cy="member_type_filter">{t('audience.filters.memberType.title')}</span>}
                >
                    <MemberTypeFilter {...props} />
                </Panel>

                <Panel
                    showArrow={false}
                    extra={endLineArrow}
                    header={<span data-cy="shopper_status_filter">{t('audience.filters.shopperStatus.title')}</span>}
                >
                    <ShopperStatusFilter {...props} />
                </Panel>

                <Panel
                    showArrow={false}
                    extra={endLineArrow}
                    header={
                        <span data-cy="registration_date_filter">{t('audience.filters.registrationDate.title')}</span>
                    }
                >
                    <RegistrationDateFilter {...props} />
                </Panel>
            </Collapse>
        </div>
    )
}

export default Filters
